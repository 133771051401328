import './StatusSupervisionHighLevel.scss'
import OverviewSection from '../../../../common/components/overviewSection/OverviewSection'
import StandardSearchButton from '../../../../common/components/standardButton/standardSearchButton/StandardSearchButton'
import { IncidentCollectionRequestType } from '../../../../common/types/IncidentCollectionRequestType'
import { useEffect, useState } from 'react'
import IIncidentOverview from '../../../../common/interfaces/incidentOverview/IIncidentOverview'
import imageBackground from './images/supervision.png'


type ComponentProps = {
  incidentOverview:null|undefined|IIncidentOverview
}

const StatusSupervisionHighLevel = (props:ComponentProps) => {

  const supervisionUserCount = 16;

  return (
    <OverviewSection contextID='statusSupervisionHighLevel' incidentOverview={props.incidentOverview} headerTitle='Tilsyn med andre brugere' headerStatus={supervisionUserCount ? (supervisionUserCount + ' stk.') : ''}>
      <div id='status-supervision-high-level'>
        <img id='status-supervisor-background-image' src={imageBackground}></img>
      </div>
    </OverviewSection>
  )
}

export default StatusSupervisionHighLevel