import IncidentListColumnHeaders from './incidentList/incidentListColumnHeaders/IncidentListColumnHeaders'
import IncidentList from './incidentList/IncidentList'
import { IncidentCollectionRequestType } from './../../common/types/IncidentCollectionRequestType'
import './IncidentCollection.scss'
import IEntityIncident from '../../common/interfaces/entityIncident/IEntityIncident'
import IncidentCollectionSortDetailsType from '../../common/types/IncidentCollectionSortType'
import IEntityIncidentsUnderRemoval from '../../common/interfaces/entityIncident/IEntityIncidentsUnderRemoval'
import IApplicationSettings from '../../common/interfaces/applicationSettings/IApplicationSettings'
import IUserIncidentDecisionMakerClue from '../../common/interfaces/entityIncident/IUserIncidentDecisionMakerClue'

type ComponentProps = {
  customerID:string
  domain:string
  incidentDecisionMakerClueFilter: null|undefined|string
  incidentCollectionRequest: IncidentCollectionRequestType
  handleIncidentCollectionChangeRequest: (request:IncidentCollectionRequestType) => void
  handleIncidentCollectionChange: (incidentCollection:IEntityIncident[]) => void
  incidentSelection?: IEntityIncident[]
  handleIncidentSelectionChange: () => void
  handleEntityIncidentsToRemoveChange:(entityIncidents:IEntityIncident[]) => void
  entityIncidentsUnderRemoval:null|undefined|IEntityIncidentsUnderRemoval
  incidentCollectionSortDetails: IncidentCollectionSortDetailsType
  handleRefreshApplication: () => void
  handleIncidentCollectionSortDetailsChange: (sortDetails:IncidentCollectionSortDetailsType) => void
  handleIncidentOverviewRequest: () => void
  applicationSettings: null|undefined|IApplicationSettings
  userIncidentDecisionMakerClues:IUserIncidentDecisionMakerClue[]
}

const IncidentCollection = (props:ComponentProps) => {

  return (
    <div id='incident-collection'>
      <div id='incident-list-column-header-wrapper'>
        <IncidentListColumnHeaders 
          key={props.incidentCollectionRequest.Title}
          customerID={props.customerID}
          incidentSelection={props.incidentSelection}
          handleIncidentSelectionChange={props.handleIncidentSelectionChange}
          handleEntityIncidentsToRemoveChange={props.handleEntityIncidentsToRemoveChange}
          incidentCollectionSortDetails={props.incidentCollectionSortDetails}
          incidentCollectionRequest={props.incidentCollectionRequest} 
          handleIncidentCollectionChangeRequest={props.handleIncidentCollectionChangeRequest}
          handleRefreshApplication={props.handleRefreshApplication}
          handleIncidentCollectionSortDetailsChange={props.handleIncidentCollectionSortDetailsChange}
          handleIncidentOverviewRequest={props.handleIncidentOverviewRequest}
          applicationSettings={props.applicationSettings}
        />
      </div>
      <div id='incident-list-wrapper'>
        <IncidentList 
          key={props.incidentCollectionRequest.Title}
          customerID={props.customerID} 
          domain={props.domain}
          incidentDecisionMakerClueFilter={props.incidentDecisionMakerClueFilter}
          incidentCollectionRequest={props.incidentCollectionRequest} 
          handleIncidentCollectionChangeRequest={props.handleIncidentCollectionChangeRequest}
          handleIncidentCollectionChange={props.handleIncidentCollectionChange}
          incidentSelection={props.incidentSelection}
          handleIncidentSelectionChange={props.handleIncidentSelectionChange}
          handleEntityIncidentsToRemoveChange={props.handleEntityIncidentsToRemoveChange}
          entityIncidentsUnderRemoval={props.entityIncidentsUnderRemoval}
          incidentCollectionSortDetails={props.incidentCollectionSortDetails}
          handleRefreshApplication={props.handleRefreshApplication}
          handleIncidentCollectionSortDetailsChange={props.handleIncidentCollectionSortDetailsChange}
          handleIncidentOverviewRequest={props.handleIncidentOverviewRequest}
          applicationSettings={props.applicationSettings}
          userIncidentDecisionMakerClues={props.userIncidentDecisionMakerClues}
        />
      </div>
    </div>
  )

}

export default IncidentCollection