import './Menu.scss'
import Logo from './logo/Logo'
import StatusCommand from './statusCommand/StatusCommand'
import SettingsCommand from './settingsCommand/SettingsCommand'
import CurrentIncidentCollectionTab from './currentIncidentCollectionTab/CurrentIncidentCollectionTab'
import User from './user/User'
import DomainSelector from './domainSelector/DomainSelector'
import IncidentCollectionRequestType from './../../common/types/IncidentCollectionRequestType'
import IIncidentOverview from './../../common/interfaces/incidentOverview/IIncidentOverview'
import IEntityIncident from '../../common/interfaces/entityIncident/IEntityIncident'
import { customerKey } from '../../configuration'
import ICustomer from '../../common/interfaces/customer/ICustomer'
import { useEffect, useState } from 'react'
import GetUserIncidentDecisionMakerClues from '../../common/services/incidentDecisionMakerClue/IncidentDecisionMakerClueService'
import { useMsal } from '@azure/msal-react'

import IncidentDecisionMakerClueFilterSelector from './incidentDecisionMakerClueFilterSelector/IncidentDecisionMakerClueFilterSelector'
import IUserIncidentDecisionMakerClue from '../../common/interfaces/entityIncident/IUserIncidentDecisionMakerClue'

type ComponentProps = {
  customerID:string
  domain:string
  incidentOverview:null|undefined|IIncidentOverview
  handleRefreshApplication: () => void
  handleSettingsMinimizeOverviewWhenRequestingCollectionChange: (value:boolean) => void
  settingsMinimizeOverviewWhenRequestingCollection: boolean
  handleSettingsAllowDomainVideoChannelChange: (value:boolean) => void
  handleDomainChangeRequest: (domain:string) => void
  handleIncidentDecisionMakerClueFilterChangeRequest: (incidentDecisionMakerClueFilter:null|undefined|string) => void
  handleIncidentCollectionChangeRequest: (request:IncidentCollectionRequestType) => void
  handleIncidentOverviewRequest: () => void
  settingsAllowDomainVideoChannel: boolean
  incidentCollectionRequest?: IncidentCollectionRequestType
  incidentCollection?: IEntityIncident[]
  handleIncidentCollectionClose: () => void
  userIncidentDecisionMakerClues:IUserIncidentDecisionMakerClue[]
}

const Menu = (props:ComponentProps) => {

  return (
    <div id='menu' className={props.incidentCollection ? 'menu-no-default-dark' : 'menu-default-dark'}>
    <div id='logo-wrapper' className='gap-to-next'>
      <Logo 
        incidentCollectionRequest={props.incidentCollectionRequest} 
        handleIncidentOverviewRequest={props.handleIncidentOverviewRequest}
        handleIncidentCollectionChangeRequest={props.handleIncidentCollectionChangeRequest}
      />
    </div>
    <div id='status-command-wrapper' className='gap-to-next'>
      {props.incidentOverview?.status?.detailedAnalysis?.userIncidentScores && 
        <StatusCommand 
          incidentOverview={props.incidentOverview} 
        />
      }
    </div>
    <div id='settings-command-wrapper' className='gap-to-next'>
      <SettingsCommand 
        handleSettingsMinimizeOverviewWhenRequestingCollectionChange={props.handleSettingsMinimizeOverviewWhenRequestingCollectionChange}
        settingsMinimizeOverviewWhenRequestingCollection={props.settingsMinimizeOverviewWhenRequestingCollection}
        handleSettingsAllowDomainVideoChannelChange={props.handleSettingsAllowDomainVideoChannelChange}
        settingsAllowDomainVideoChannel={props.settingsAllowDomainVideoChannel}
      />
    </div>
    <div id='domain-selector-wrapper' className='gap-to-next'>
      <DomainSelector 
        handleDomainChangeRequest={props.handleDomainChangeRequest}
      />
    </div>
    <div id='incident-decision-maker-clue-filter-selector-wrapper' className='gap-to-next'>
      {
        props.userIncidentDecisionMakerClues && 
        props.userIncidentDecisionMakerClues.length > 0 && 
        props.userIncidentDecisionMakerClues.find(incidentDecisionMakerClue => incidentDecisionMakerClue.isSecondary === true) &&
        <IncidentDecisionMakerClueFilterSelector 
          customerID={props.customerID}
          domain={props.domain}
          userIncidentDecisionMakerClues={props.userIncidentDecisionMakerClues}
          handleIncidentDecisionMakerClueFilterChangeRequest={props.handleIncidentDecisionMakerClueFilterChangeRequest}
        />    
      }
    </div>    
    <div id='current-incident-collection-tab-wrapper' className='gap-to-next'>
      {
        props.incidentCollectionRequest &&
        <CurrentIncidentCollectionTab 
          incidentCollectionRequest={props.incidentCollectionRequest} 
          incidentCollection={props.incidentCollection} 
          handleIncidentCollectionClose={props.handleIncidentCollectionClose}/>
      }
    </div>
    <div id='user-wrapper'>
      <User />
    </div>
    </div>
  )
}

export default Menu