// TODO: any is used several places. Tydes should be used (thats why its TypeScript.)

import GetIncidentOverview from './common/services/incidentOverview/IncidentOverviewService'
import Menu from './components/menu/Menu'
import IncidentOverview from './components/incidentOverview/IncidentOverview'
import IncidentCollection from './components/incidentCollection/IncidentCollection'
import { useEffect, useState } from 'react'
import IncidentCollectionRequestType from './common/types/IncidentCollectionRequestType'
import IncidentActionRequestType from './common/types/IncidentActionRequestType'
import IIncidentOverview from './common/interfaces/incidentOverview/IIncidentOverview'
import { AuthenticatedTemplate, MsalProvider, useMsal } from '@azure/msal-react'
import { AccountInfo, IPublicClientApplication, InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser'
import IEntityIncident from './common/interfaces/entityIncident/IEntityIncident'
import IncidentCollectionSortDetailsType from './common/types/IncidentCollectionSortType'
import ICustomer from './common/interfaces/customer/ICustomer'
import CustomerPresentation from './components/customerPresentation/CustomerPresentation'
import { msalMainApiRequest } from './configuration'
import GetCustomer from './common/services/customer/CustomerService'
import './App.scss'
import IEntityIncidentsUnderRemoval from './common/interfaces/entityIncident/IEntityIncidentsUnderRemoval'
import IApplicationSettings from './common/interfaces/applicationSettings/IApplicationSettings'
import GetApplicationSettings from './common/services/applicationSettings/ApplicationSettingService'
import IUserIncidentDecisionMakerClue from './common/interfaces/entityIncident/IUserIncidentDecisionMakerClue'
import GetUserIncidentDecisionMakerClues from './common/services/incidentDecisionMakerClue/IncidentDecisionMakerClueService'

type AppProps = {
  publicClientApplication: IPublicClientApplication
}

const App = (props:AppProps) => {

  const [userIncidentDecisionMakerClues, setUserIncidentDecisionMakerClues] = useState<IUserIncidentDecisionMakerClue[]>([])
  const [applicationSettings, setApplicationSettings] = useState<null|undefined|IApplicationSettings>(null)
  const [customer, setCustomer] = useState<ICustomer>()
  const [domain, setDomain] = useState<string>()
  const [incidentDecisionMakerClueFilter, setIncidentDecisionMakerClueFilter] = useState<null|undefined|string>()
  const [initialIncidentType, setInitialIncidentType] = useState<null|string>(null)
  const [incidentOverview, setIncidentOverview] = useState<null|undefined|IIncidentOverview>()
  const [incidentCollection, setIncidentCollection] = useState<IEntityIncident[]>()
  const [incidentCollectionRequest, setIncidentCollectionRequest] = useState<IncidentCollectionRequestType>()
  const [incidentSelection, setIncidentSelection] = useState<IEntityIncident[]>()
  const [entityIncidentsUnderRemoval, setEntityIncidentsUnderRemoval] = useState<null|undefined|IEntityIncidentsUnderRemoval>()
  const [refreshApplication, setRefreshApplication] = useState<string>('')
  const [incidentCollectionCloseRequest, setIncidentCollectionCloseRequest] = useState<string>('')
  const [settingsMinimizeOverviewWhenRequestingCollection, setSettingsMinimizeOverviewWhenOpeningCollection] = useState(false)
  const [settingsAllowDomainVideoChannel, setSettingsAllowDomainVideoChannel] = useState(false)
  const [settingsIncidentOverviewShallow, setSettingsIncidentOverviewShallow] = useState(false)
  const [incidentActionRequest, setIncidentActionRequest] = useState<IncidentActionRequestType>()
  const [incidentCollectionSortDetails, setIncidentCollectionSortDetails] = useState<IncidentCollectionSortDetailsType>(
    {
      sortAcsending: [true, true, false],
      activeIndex: 2
    }
  )

  const fixedDomain = 'Sagsbehandler+' // ToDo.

  // Constructor.
  useEffect(() => {

    const parameters:URLSearchParams = new URLSearchParams(window.location.search)
    const parameterCustomerID:string|null = parameters.get('id')
    const parameterIncidentType:string|null = parameters.get('incident-type')

    // Parameter asking for a specific incident type to be open. 
    if (parameterIncidentType) 
    {
      setInitialIncidentType(parameterIncidentType)
    }

    if (parameterCustomerID) {
      GetCustomer(props.publicClientApplication, parameterCustomerID)
        .then(response => 
          {
            setCustomer(response)
          })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            props.publicClientApplication.acquireTokenRedirect({
              ...msalMainApiRequest,
              account: props.publicClientApplication.getActiveAccount() as AccountInfo
            })
          }
      })   
    }

  }, [])


  useEffect(() => { 

    if (customer) { 

      GetApplicationSettings(props.publicClientApplication, customer.customerID)
      .then(response => {      
        setApplicationSettings(response)
      })
      .catch((error) => {
        console.log(error)
      })   

      GetUserIncidentDecisionMakerClues(props.publicClientApplication, customer.customerID, fixedDomain).then(
        clues => setUserIncidentDecisionMakerClues(clues)
      )

      // Retrieve items for removal in local storage if any.
      // Strip old values.
      const entityIncidentsUnderRemovalFromStorage:null|IEntityIncidentsUnderRemoval = getEntityIncidentsUnderRemovalStorage()
      if (entityIncidentsUnderRemovalFromStorage) {
        let entityIncidentsUnderRemovalFiltered:IEntityIncidentsUnderRemoval = filterEntityIncidentsUnderRemoval(entityIncidentsUnderRemovalFromStorage)
        setEntityIncidentsUnderRemovalStorage(entityIncidentsUnderRemovalFiltered)
        setEntityIncidentsUnderRemoval(entityIncidentsUnderRemovalFiltered)
      }

      setDomain(fixedDomain)
      setIncidentDecisionMakerClueFilter('primary')
    }

  }, [customer])

  useEffect(() => { 
    if (domain) handleRefreshApplicationRequest()
  }, [domain, incidentDecisionMakerClueFilter])

  useEffect(() => {

    if (refreshApplication) {
      handleIncidentCollectionCloseRequest()
      handleIncidentOverviewRequest()

      if (initialIncidentType) {
        setInitialIncidentType(null)
        handleIncidentCollectionChangeRequest({Title: initialIncidentType, Area: 'NameRequest', Value: {StringValue: initialIncidentType}, Timestamp: new Date()})       
      }
    }

  }, [refreshApplication])


  useEffect(() => {

    if (incidentCollectionRequest) {
      var viewportWidth = window.innerWidth;
      const optimizeRealEstateThreshold = 1300;  
      if (viewportWidth < optimizeRealEstateThreshold)
        setSettingsIncidentOverviewShallow(true)
      else
        setSettingsIncidentOverviewShallow(settingsMinimizeOverviewWhenRequestingCollection)
    }

  }, [incidentCollectionRequest])

  const filterEntityIncidentsUnderRemoval = (entityIncidentsToRemove:IEntityIncidentsUnderRemoval) => {
    let minimumDate:Date = retractMinutesFromDate(new Date(), 2)
    for (let index = (entityIncidentsToRemove.items.length - 1); index >= 0; index--) {
      let itemDate:Date = entityIncidentsToRemove.items[index].timestamp
      if (new Date(itemDate) < new Date(minimumDate)) entityIncidentsToRemove.items.splice(index, 1)
    }
    return entityIncidentsToRemove;
  }

  const retractMinutesFromDate = (date:Date, minutes:number) => {
    date.setMinutes(date.getMinutes() - minutes);
    return date;
  }
  
  const getEntityIncidentsUnderRemovalStorage = ():null|IEntityIncidentsUnderRemoval => {
    
    const entityIncidentsUnderRemovalJson:null|string = localStorage.getItem('entityIncidentsUnderRemoval')
    return entityIncidentsUnderRemovalJson ? 
        JSON.parse(entityIncidentsUnderRemovalJson) as IEntityIncidentsUnderRemoval :
        null
  }
  
  const setEntityIncidentsUnderRemovalStorage = (entityIncidentsUnderRemoval:IEntityIncidentsUnderRemoval) => {
    localStorage.setItem('entityIncidentsUnderRemoval', JSON.stringify(entityIncidentsUnderRemoval));
 }

  const handleDomainChangeRequest = (domain:string) => { 
    setDomain(domain) 
  }

  const handleIncidentDecisionMakerClueFilterChangeRequest = (incidentDecisionMakerClueFilter:null|undefined|string) => { 
    setIncidentDecisionMakerClueFilter(incidentDecisionMakerClueFilter) 
  }

  const handleRefreshApplicationRequest = () => { 
    setRefreshApplication((new Date()).toString()) 
  }

  const handleIncidentCollectionCloseRequest = () => {
    setIncidentCollectionRequest(undefined)
    setIncidentCollection(undefined)
    setSettingsIncidentOverviewShallow(false)
    setIncidentCollectionCloseRequest((new Date()).toString())
  }

  const handleIncidentOverviewRequest = () => {
    if (domain) {
      if (customer?.customerID) {
        GetIncidentOverview(props.publicClientApplication, customer?.customerID, domain, incidentDecisionMakerClueFilter)
          .then(
            response => handleIncidentOverviewRequestResult(response))
              .catch((e) => {
                if (e instanceof InteractionRequiredAuthError) {
                  props.publicClientApplication.acquireTokenRedirect({
                    ...msalMainApiRequest,
                    account: props.publicClientApplication.getActiveAccount() as AccountInfo
            })
          }
        })
      }
    }
  }

  const handleIncidentOverviewRequestResult = (incidentOverview:IIncidentOverview) => { 
    setIncidentOverview(incidentOverview) 
  }

  const handleSettingsMinimizeOverviewWhenRequestingCollectionChange = (value:boolean) => { 
    setSettingsMinimizeOverviewWhenOpeningCollection(value) 
  }

  const handleSettingsAllowDomainVideoChannelChange = (value:boolean) => { 
    setSettingsAllowDomainVideoChannel(value) 
  }

  const handleIncidentCollectionSortDetailsChange = (sortDetails:IncidentCollectionSortDetailsType) => {
    setIncidentCollectionSortDetails
      (
        {
          sortAcsending: 
            [
              sortDetails.sortAcsending[0],
              sortDetails.sortAcsending[1],
              sortDetails.sortAcsending[2]
            ],
          activeIndex: sortDetails.activeIndex
        }
      )
  }

  const handleIncidentCollectionChangeRequest = (request:IncidentCollectionRequestType) => {

    if (entityIncidentsUnderRemoval && entityIncidentsUnderRemoval?.items.length > 0)
      handleIncidentOverviewRequest()

    setIncidentCollectionRequest({  
      Title: request.Title,
      Area: request.Area,
      Value: {
        StringValue: request.Value?.StringValue,
        DatetimeValue: request.Value?.DatetimeValue,
        NumberValue: request.Value?.NumberValue
      },
      Timestamp: request.Timestamp
    })
  }

  const handleIncidentCollectionChange = (incidentCollection:IEntityIncident[]) => { 
    setIncidentCollection(incidentCollection) 
  }

  const handleIncidentSelectionChange = () => {
    if (!incidentCollection) return  
    setIncidentSelection(
      incidentCollection.filter((entityIncident:IEntityIncident) => {
        const elementID:string = 
          'selected-checkbox-' + 
          entityIncident.entity.entityType + '-' + 
          entityIncident.entity.entityID + '-' + 
          entityIncident.incident.name
        const element:HTMLInputElement|null = (document.getElementById(elementID) as HTMLInputElement)
        return (element && element.checked)
      })
    )
  }

  const handleIncidentActionChangeRequest = (request:IncidentActionRequestType) => {
    setIncidentActionRequest({
      Action: request.Action,
      Value: {
        StringValue: request.Value?.StringValue
      },
      Timestamp: request.Timestamp
    })
  }

  const handleEntityIncidentsToRemoveChange = (entityIncidents:IEntityIncident[]) => {

    let entityIncidentsUnderRemovalLocal:IEntityIncidentsUnderRemoval = {items: []}    

    entityIncidentsUnderRemoval?.items.map(item => (
      entityIncidentsUnderRemovalLocal?.items.push({
        timestamp: item.timestamp,
        entityID: item.entityID,
        incidentName: item.incidentName,
        incidentSeverityLevel: item.incidentSeverityLevel
      })
    ))

    entityIncidents.map((entityIncident:IEntityIncident) => (
      entityIncidentsUnderRemovalLocal?.items.push({
        timestamp: new Date(),
        entityID: entityIncident.entity.entityID,
        incidentName: entityIncident.incident.name,
        incidentSeverityLevel: entityIncident.incident.severityLevel
      })
    ))

    let entityIncidentsUnderRemovalFiltered:IEntityIncidentsUnderRemoval = filterEntityIncidentsUnderRemoval(entityIncidentsUnderRemovalLocal)
    setEntityIncidentsUnderRemovalStorage(entityIncidentsUnderRemovalFiltered)
    setEntityIncidentsUnderRemoval(entityIncidentsUnderRemovalFiltered)

  }

  const handleOverviewWrapperShadowPanelClick = () => { 
    setSettingsIncidentOverviewShallow(false) 
  }
  
  return (
      <MsalProvider instance={props.publicClientApplication}>
      {
        customer?.customerID &&
        <div id='application'>
          <div id='menu-wrapper'>
          {
            domain && 
            <Menu
              customerID={customer.customerID} 
              domain={domain} 
              incidentOverview={incidentOverview}
              handleRefreshApplication={handleRefreshApplicationRequest}
              handleSettingsMinimizeOverviewWhenRequestingCollectionChange={handleSettingsMinimizeOverviewWhenRequestingCollectionChange}
              settingsMinimizeOverviewWhenRequestingCollection={settingsMinimizeOverviewWhenRequestingCollection}
              settingsAllowDomainVideoChannel={settingsAllowDomainVideoChannel}
              handleDomainChangeRequest={handleDomainChangeRequest}
              handleIncidentDecisionMakerClueFilterChangeRequest={handleIncidentDecisionMakerClueFilterChangeRequest}
              handleIncidentCollectionChangeRequest={handleIncidentCollectionChangeRequest}
              handleIncidentOverviewRequest={handleIncidentOverviewRequest}
              handleSettingsAllowDomainVideoChannelChange={handleSettingsAllowDomainVideoChannelChange}
              incidentCollectionRequest={incidentCollectionRequest}
              incidentCollection={incidentCollection} 
              handleIncidentCollectionClose={handleIncidentCollectionCloseRequest}
              userIncidentDecisionMakerClues={userIncidentDecisionMakerClues}
              />  
          }
          </div>
          <div id='content-wrapper'>
            <AuthenticatedTemplate>
              {
                <>
                  <div id='overview-wrapper' className={settingsIncidentOverviewShallow ? 'overview-wrapper-shallow' : 'overview-wrapper-normal'}>
                    <div id='overview-wrapper-shallow-panel' onClick={() => handleOverviewWrapperShadowPanelClick()}>
                      <div id='overview-wrapper-shallow-panel-symbol'></div>
                    </div>
                    <div id='overview-wrapper-content'>
                      {
                        domain &&
                        <IncidentOverview 
                            customerID={customer.customerID}
                            domain={domain} 
                            incidentDecisionMakerClueFilter={incidentDecisionMakerClueFilter}
                            incidentOverview={incidentOverview}
                            settingsMinimizeOverviewWhenRequestingCollection={settingsAllowDomainVideoChannel}
                            handleIncidentCollectionChangeRequest={handleIncidentCollectionChangeRequest}  
                            handleIncidentActionChangeRequest={handleIncidentActionChangeRequest}
                            handleEntityIncidentsToRemoveChange={handleEntityIncidentsToRemoveChange}
                            incidentCollectionCloseRequest={incidentCollectionCloseRequest}
                            handleIncidentOverviewRequest={handleIncidentOverviewRequest}
                          />

                      }
                    </div>
                  </div>
                  <div id='active-result-wrapper'>

                    {
                      !incidentCollectionRequest &&
                      <div id='customer-presentation-wrapper'>
                        <CustomerPresentation customer={customer}/>
                      </div>
                    }

                    {
                      incidentCollectionRequest && 
                      <div id='incident-collection-wrapper'>
                        {
                          domain &&
                          <IncidentCollection 
                            customerID={customer.customerID}
                            domain={domain} 
                            incidentDecisionMakerClueFilter={incidentDecisionMakerClueFilter}
                            incidentCollectionRequest={incidentCollectionRequest}
                            handleIncidentCollectionChangeRequest={handleIncidentCollectionChangeRequest}
                            handleIncidentCollectionChange={handleIncidentCollectionChange}
                            incidentSelection={incidentSelection}
                            handleIncidentSelectionChange={handleIncidentSelectionChange}
                            handleEntityIncidentsToRemoveChange={handleEntityIncidentsToRemoveChange}
                            entityIncidentsUnderRemoval={entityIncidentsUnderRemoval}
                            incidentCollectionSortDetails={incidentCollectionSortDetails}
                            handleRefreshApplication={handleRefreshApplicationRequest}
                            handleIncidentCollectionSortDetailsChange={handleIncidentCollectionSortDetailsChange}
                            handleIncidentOverviewRequest={handleIncidentOverviewRequest}
                            applicationSettings={applicationSettings}
                            userIncidentDecisionMakerClues={userIncidentDecisionMakerClues}
                          />
                        }
                      </div>

                    }

                  </div>

                </>
              }
            </AuthenticatedTemplate>
          </div>
        </div>
      }
    </MsalProvider>
  )
}



export default App