import axios, { AxiosRequestConfig } from 'axios'
import IEntityIncident from '../../interfaces/entityIncident/IEntityIncident'
import { IncidentCollectionRequestType } from '../../types/IncidentCollectionRequestType'
import { IPublicClientApplication } from '@azure/msal-browser'
import { reactMainApiBaseUrl, reactMainApiIncidentRetrievalPoolSize, customerKey, msalMainApiRequest } from "../../../configuration"
import GetAccessToken from '../../functions/security/AccessTokenProvider'

export default async function GetIncidents(publicClientApplication:IPublicClientApplication, customerID:string, domain:string, incidentDecisionMakerClueFilter:string|null|undefined, incidentCollectionRequest: IncidentCollectionRequestType):Promise<IEntityIncident[]> {

  const accessToken = await GetAccessToken(publicClientApplication, msalMainApiRequest)
  const parameters = await createRequestParameters(customerID, domain, incidentDecisionMakerClueFilter, incidentCollectionRequest)
  const requestConfiguration:AxiosRequestConfig = {
    url: (reactMainApiBaseUrl(customerID) + '/incidents'),
    method: 'GET',
    params: parameters,
    headers: { Authorization: ('Bearer ' + accessToken)}
  }

  return axios(requestConfiguration)
    .then(result => result.data.incidents)
    .catch(event => Error("Could not retrieve incidents from Sense React Api.\n\n" + event))
}

const createRequestParameters = async (customerID:string, domain:string, incidentDecisionMakerClueFilter:string|null|undefined, incidentCollectionRequest:IncidentCollectionRequestType) => {

  const parameters = new URLSearchParams()

  parameters.append('maximum-incident-count', reactMainApiIncidentRetrievalPoolSize.toString())
  parameters.append('code', customerKey)
  parameters.append('id', customerID)
  parameters.append('domain', domain)
  if (incidentDecisionMakerClueFilter) parameters.append('source', incidentDecisionMakerClueFilter)
  switch(incidentCollectionRequest.Area) {
    case 'PredefinedRequest': {
      if (incidentCollectionRequest.Value?.StringValue) 
        parameters.append('predefined-request', incidentCollectionRequest.Value.StringValue.toString())
      break
    }
    case 'ClearCommandRequest': {
      if (incidentCollectionRequest.Value?.StringValue) 
        parameters.append('clear-command-request', incidentCollectionRequest.Value.StringValue.toString())
      break
    }
    case 'NameRequest': {
      if (incidentCollectionRequest.Value?.StringValue)
        parameters.append('name', incidentCollectionRequest.Value.StringValue.toString())
      break
    }
    case 'EntityTypeRequest': {
      if (incidentCollectionRequest.Value?.StringValue)
        parameters.append('entity-type', incidentCollectionRequest.Value.StringValue.toString())
      break
    }
    case 'EntityTitleRequest': {
      if (incidentCollectionRequest.Value?.StringValue)
        parameters.append('entity-title', incidentCollectionRequest.Value.StringValue.toString())
      break
    }
    case 'EntityIDRequest': {
      if (incidentCollectionRequest.Value?.StringValue)
        parameters.append('entity-id', incidentCollectionRequest.Value.StringValue.toString())
      break
    }
    case 'EntityFriendlyIDRequest': {
      if (incidentCollectionRequest.Value?.StringValue)
        parameters.append('entity-friendly-id', incidentCollectionRequest.Value.StringValue.toString())
      break
    }
    case 'EntityFriendlyContainerRequest': {
      if (incidentCollectionRequest.Value?.StringValue)
        parameters.append('entity-friendly-wrapper', incidentCollectionRequest.Value.StringValue.toString())
      break
    }
    case 'EntityDefaultTimestampRequest': {
      if (incidentCollectionRequest.Value?.DatetimeValue)
        parameters.append('entity-default-timestamp', incidentCollectionRequest.Value.DatetimeValue.toString())
      break
    }
    case 'MatchResultMatchRequest': {
      if (incidentCollectionRequest.Value?.StringValue)
        parameters.append('match-result-match', incidentCollectionRequest.Value.StringValue.toString())
      break
    }
    case 'MatchResultAssessmentRequest': {
      if (incidentCollectionRequest.Value?.StringValue)
        parameters.append('match-result-assessment', incidentCollectionRequest.Value.StringValue.toString())
      break
    }
    case 'ObservationTimestampRequest': {
      if (incidentCollectionRequest.Value?.DatetimeValue)
        parameters.append('observation-timestamp', incidentCollectionRequest.Value.DatetimeValue.toString())
      break
    }      
    case 'SeverityLevelRequest': {
      if (incidentCollectionRequest.Value?.NumberValue)
        parameters.append('severity-level', (incidentCollectionRequest.Value.NumberValue.toString()))
      break
    }
    case 'WorkloadRequest': {
      if (incidentCollectionRequest.Value?.NumberValue) 
        parameters.append('workload', (incidentCollectionRequest.Value.NumberValue.toString()))
      break
    }
    case 'FreeTextRequest': {
      if (incidentCollectionRequest.Value?.StringValue)
       parameters.append('free-text', incidentCollectionRequest.Value.StringValue.toString())
      break
    }
    default: {
    }
  }

  return parameters
}