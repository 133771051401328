import axios, { AxiosRequestConfig } from 'axios'
import { IPublicClientApplication } from '@azure/msal-browser'
import { reactMainApiBaseUrl, reactMainApiIncidentRetrievalPoolSize, customerKey, msalMainApiRequest } from "../../../configuration"
import GetAccessToken from '../../functions/security/AccessTokenProvider'
import IUserIncidentDecisionMakerClue from '../../interfaces/entityIncident/IUserIncidentDecisionMakerClue'

export default async function GetUserIncidentDecisionMakerClues(publicClientApplication:IPublicClientApplication, customerID:string, domain:string):Promise<IUserIncidentDecisionMakerClue[]> {

  const accessToken = await GetAccessToken(publicClientApplication, msalMainApiRequest)
  const parameters = await createRequestParameters(customerID, domain)
  const requestConfiguration:AxiosRequestConfig = {
    url: (reactMainApiBaseUrl(customerID) + '/decision-maker-clues'),
    method: 'GET',
    params: parameters,
    headers: { Authorization: ('Bearer ' + accessToken)},
  }

  return axios(requestConfiguration)
    .then(result => result.data)
    .catch(event => Error("Could not retrieve incident decision maker clues from Sense React Api.\n\n" + event))
}

const createRequestParameters = async (customerID:string, domain:string) => {

  const parameters = new URLSearchParams()

  parameters.append('code', customerKey)
  parameters.append('id', customerID)
  parameters.append('domain', domain)

  return parameters
}