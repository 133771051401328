import { IPublicClientApplication } from "@azure/msal-browser"
import { reactMainApiBaseUrl, customerKey, msalMainApiRequest } from "../../../configuration"
import GetAccessToken from '../../functions/security/AccessTokenProvider'
import axios, { AxiosRequestConfig } from "axios"
import IIncidentOverview from "../../interfaces/incidentOverview/IIncidentOverview"
import IncidentDecisionMakerClueFilterSelector from "../../../components/menu/incidentDecisionMakerClueFilterSelector/IncidentDecisionMakerClueFilterSelector"

export default async function GetIncidentOverview(publicClientApplication:IPublicClientApplication, customerID:string, domain:string, incidentDecisionMakerClueFilter:null|undefined|string):Promise<IIncidentOverview> {

  const accessToken = await GetAccessToken(publicClientApplication, msalMainApiRequest)
  const parameters = await createRequestParameters(customerID, domain, incidentDecisionMakerClueFilter)
  const requestConfiguration:AxiosRequestConfig = {
    url: (reactMainApiBaseUrl(customerID) + '/incident-overview'),
    method: 'GET',
    params: parameters,
    headers: { Authorization: ('Bearer ' + accessToken)},
  }

  return axios(requestConfiguration)
  .then(result => result.data)
  .catch(event => Error("Could not retrieve incident-overview from Sense React Api.\n\n" + event))
}

const createRequestParameters = async (customerID:string, domain:string, incidentDecisionMakerClueFilter:null|undefined|string) => {

  const parameters = new URLSearchParams()

  parameters.append('code', customerKey)
  parameters.append('id', customerID)
  parameters.append('domain', domain)
  if (incidentDecisionMakerClueFilter) parameters.append('source', incidentDecisionMakerClueFilter)
  return parameters
}