import { useEffect, useState } from 'react'
import './IncidentDecisionMakerClueFilterSelector.scss'
import { DropdownButton, Dropdown, DropdownItemProps } from 'react-bootstrap'
import IUserIncidentDecisionMakerClue from '../../../common/interfaces/entityIncident/IUserIncidentDecisionMakerClue'



type ComponentProps = {
  customerID: string
  domain:string
  userIncidentDecisionMakerClues:IUserIncidentDecisionMakerClue[]
  handleIncidentDecisionMakerClueFilterChangeRequest: (incidentDecisionMakerClueFilter:null|undefined|string) => void
}


const IncidentDecisionMakerClueFilterSelector = (props:ComponentProps) => {

  const [incidentDecisionMakerClueFilterList, setIncidentDecisionMakerClueFilterList] = useState<string[]>([])
  const [incidentDecisionMakerClueFilterListInitialized, setIncidentDecisionMakerClueFilterListInitialized] = useState<boolean>(false)
  const [incidentDecisionMakerClueFilterSelected, setIncidentDecisionMakerClueFilterSelected] = useState<string>('')

  useEffect(() => {
    if (!incidentDecisionMakerClueFilterListInitialized)
    {
      const filters:string[]|null = ['Dit og fælles ansvar (alle)', 'Dit ansvar', 'Fælles ansvar']
      if (props.userIncidentDecisionMakerClues)
      {
        props.userIncidentDecisionMakerClues.map((incidentDecisionMakerClue:IUserIncidentDecisionMakerClue) => {
          if (incidentDecisionMakerClue.isSecondary) 
            filters.push(incidentDecisionMakerClue.displayName)
        })
        setIncidentDecisionMakerClueFilterSelected(filters[1])
      }
      setIncidentDecisionMakerClueFilterList(filters)
      setIncidentDecisionMakerClueFilterListInitialized(true)  
    }
  }, [])

  const handleSelect = (selectedItem:any) => {

    if (!selectedItem || !incidentDecisionMakerClueFilterListInitialized) return   

    setIncidentDecisionMakerClueFilterSelected(((selectedItem > 2) ? ' - ' : '') + incidentDecisionMakerClueFilterList[selectedItem])

    let selectedIncidentSourceTyped:string|null

    switch (true) {
    case selectedItem == 0:
      selectedIncidentSourceTyped = null
      break;
    case selectedItem == 1:
      selectedIncidentSourceTyped = 'primary'
      break;
    case selectedItem == 2:
      selectedIncidentSourceTyped = 'secondary'
      break;
    case (selectedItem > 2):
      selectedIncidentSourceTyped = incidentDecisionMakerClueFilterList[selectedItem]
      break;
    default:
      selectedIncidentSourceTyped = null
    }

    props.handleIncidentDecisionMakerClueFilterChangeRequest(selectedIncidentSourceTyped)
  }  

  return (
    <div id='incident-decision-maker-clue-filter-name-selector'>
      {
        <DropdownButton id='incident-decision-maker-clue-filter-name-selection-drop-down-button' variant='secondary' onSelect={(selectedItem) => handleSelect(selectedItem)} title={incidentDecisionMakerClueFilterSelected}>
        {
          incidentDecisionMakerClueFilterList.map((incidentDecisionMakerClueFilter:string, incidentDecisionMakerClueFilterIndex:number) => (
            (
              <Dropdown.Item key={incidentDecisionMakerClueFilterIndex} eventKey={incidentDecisionMakerClueFilterIndex}>
                {
                  ((incidentDecisionMakerClueFilterIndex > 2) ? ' - ' : '') + incidentDecisionMakerClueFilterList[incidentDecisionMakerClueFilterIndex]
                }
              </Dropdown.Item>
            )
          ))
        }
        </DropdownButton>
      }
    </div>
  )
}



export default IncidentDecisionMakerClueFilterSelector
